<template>
    <div>
        <!-- Page heading -->
        <header class="bg-gray-50 flex flex-col sm:mt-0 sm:flex-row sm:flex-nowrap sm:space-x-8">
            <div class="flex flex-wrap items-center justify-between mx-6 my-4">
                <h2 class="mr-10 text-4xl font-bold leading-none md:text-5xl">
                    Networking
                </h2>
                <div class="block pb-1 mt-2">
                    <!-- <v-btn text class="subsecondary ml-2" color="secondary rounded-lg">
                        <v-icon>{{ icons.mdiShapeRectanglePlus }}</v-icon>
                        <span>Mew post</span>
                    </v-btn> -->
                </div>
            </div>
            <div>
                <header-tab :tabs="['My network', 'Sent Invitations', 'Received Invitations']"
                    @tab="toggleTabs"></header-tab>
            </div>
        </header>
        <!-- <section class="relative bg-gray-50 mt-4 pa-6 pb-20 lg:pa-8 lg:pb-28 max-h-hev"> -->
        <section class="px-0 flex flex-row">
            <article class="col-md-12">
                <!-- <ul class="nav-dash-tab">
                    <li class="text-center font-bold uppercase" v-for="(tab, i) in tabs" :key="i"
                        v-on:click="toggleTabs(i + 1)"
                        v-bind:class="{ 'nav-dash-tab-item': openTab !== (i + 1), 'nav-dash-tab-item-active': openTab === (i + 1) }">
                        {{ tab.title }}
                    </li>
                </ul> -->

                <div class="relative flex flex-col min-w-0 break-words bg-white w-full rounded">
                    <div class="px-4 py-5 flex-auto">
                        <div class="tab-content tab-space">
                            <div v-for="tab in 3" :key="tab"
                                v-bind:class="{ 'hidden': openTab !== tab, 'block': openTab === tab }">
                                <!-- {{ tab  }} -->
                                <networking-table-data :stab="tab"></networking-table-data>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <!-- <aside class="col-md-4">
                <invite-some-home></invite-some-home>
            </aside> -->
        </section>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll, mdiAccountNetwork } from '@mdi/js'
// import { Drequest } from '@/plugins/Drequest'
import NetworkingTableData from '@/views/dashboard/networking/components/NetworkingTableData.vue'
import InviteSomeHome from './InviteSomeHome.vue'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'
import HeaderTab from '@/views/dashboard/components/HeaderTab.vue'


// import md5 from '@md5'
var md5 = require("md5")
export default {
    components: {
        NetworkingTableData,
        HeaderTab,
        InviteSomeHome,
        CardHeader,
        HeaderTab
    },
    data() {
        return {
            headers: {
                titlepage: 'NETWORKING',
                icon: null,
            },
            isDataLoading: true,
            tab: 0,
            headertab: {
                initial: "Join",
                icon: mdiAccountNetwork,
                title: "Manage your network colaboration"
            },
            openTab: 1,
            tabs: [
                { title: 'My network', icon: mdiShareAll },
                { title: 'Sent Invitations', icon: mdiShareAll },
                { title: 'Received Invitations', icon: mdiEye },
            ],
            tabinvite: { title: 'Invite someone', icon: mdiEye }
        };
    },
    methods: {
        toggleTabs: function (tabNumber) {
            this.openTab = tabNumber
        }
    },
    setup(props) {
        const gettingData = props.listData

        const title = props.title
        const icons = { mdiMagnify, mdiEye, mdiShareAll, mdiAccountNetwork }
        let search = ref('')
        const headers = [
            { text: 'No', value: 'noIndex' },
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'user',
            },
            { text: 'Status', value: 'status' },
            // { text: 'Date', value: 'date' },
            {
                text: 'More actions',
                // align: 'center',
                value: 'actions', sortable: false
            },
        ]
        return {
            gettingData,
            title,
            icons,

            search,
            headers
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';


.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}

.whitetile {
    color: white;
}
</style>